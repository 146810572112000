body.gm-menu-installed .gm-menu .gm-item.gm-level-0>.gm-target {
    padding: 30px 15px!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

html body.gm-menu-installed div#shopify-section-header .gm-menu .gm-item.gm-level-0>.gm-target>span.gm-text {
    letter-spacing: 1.5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 14px!important;
}

body.gm-menu-installed .gm-menu {
    height: 100%
}

body.gm-menu-installed .gm-menu .gm-contact .gm-grid-item {
    padding-left: 0;
    padding-right: 30px
}

.gm-menu-installed .gm-menu .gm-links>.gm-heading {
    border-bottom: 1px solid #666!important
}

.site-header .site-header__mobile-nav {
    position: unset
}

body.gm-menu-installed .gm-menu .gm-submenu.gm-mega {
    opacity: .97!important;
}

.gm-menu-installed .gm-menu ul.gm-grid .gm-image>a {
    padding: 0 !important;
}

.gm-menu-installed .gm-menu .gm-links>li:not(.gm-heading)>.gm-target,.gm-menu-installed .gm-menu .gm-links>li:not(.gm-heading)>a {
    padding: 10px 0
}

.gm-menu:not(.gm-menu-mobile) .bulzTw,.gm-menu:not(.gm-menu-mobile) .glDLMl,.gm-menu:not(.gm-menu-mobile) .hfgtNF,.gm-menu:not(.gm-menu-mobile) .jdqwIA,.gm-menu:not(.gm-menu-mobile) .kaoYbt,.gvMKLJ,.hfBjhj,.jFkahz,.jKpLlf,.llIhKQ,body.gm-menu-installed .gm-menu ul.gm-grid+p {
    display: none;
    opacity: 0
}

.gm-menu-installed .gm-menu ul.gm-grid .gm-image img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.gm-html .gm-html-inner span.font_md {
    font-size: 25px;
    display: block;
    margin-bottom: -25px
}

.gm-html .gm-html-inner span.ct-title:first-of-type {
    padding-bottom: 0
}

.gm-html .gm-html-inner span.ct-title {
    padding-bottom: 5px;
    display: inline-block;
    font-size: 17px
}

.gm-menu .gm-contact .gm-grid-item.gmcol-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.gm-menu .gm-contact .gm-grid-item textarea {
    height: 60px
}

.gm-menu .gm-contact,.gm-menu .gm-html {
    padding: 2px 0 12px 5px!important
}

body.gm-menu-installed .gm-menu .gm-submenu .gm-item .gm-heading .gm-target,body.gm-menu-installed .gm-menu .gm-submenu .gm-item .gm-target {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.gm-menu-installed .gm-menu .gm-submenu .gm-item .gm-heading span.gm-text {
    color: #fff!important;
    order: 1;
    display: contents
}

.site-footer p {
    font-size: 12px
}

.gm-menu-installed .gm-menu .gm-submenu .gm-item .gm-heading span.gm-icon.gm-icon-img {
    order: 2;
    margin-left: 10px
}

.gm-html .gm-html-inner em {
    font-style: normal
}

.gm-html .gm-html-inner .op_hours {
    float: left;
    width: 100%;
    margin-bottom: 15px
}

.gm-html .gm-html-inner .op_hours em.hr_left {
    float: left;
    width: 25%
}

.gm-html .gm-html-inner .op_hours em.hr_right {
    float: left;
    width: 25%;
    text-align: right
}

.announcement-bar__message a:hover {
    color: #000!important
}

.product-single__media-wrapper a.product-single__media {
    display: block
}

.grid-view-item__image {
    height: auto;
}

@media (min-width: 750px) {
    .site-header>.site-header__mobile-nav {
        height:100px
    }

    .home-carousel-products .fp-left-col {
        margin-top: -25px
    }

    .gm-menu-installed .gm-menu ul.gm-grid {
        width: 50%;
        min-width: 50%;
        margin: 0 auto
    }

    .gm-menu-installed .gm-menu ul.gm-grid>li:first-child {
        padding-left: 2px
    }

    .gm-menu-installed .gm-menu ul.gm-grid>li:last-child {
        padding-right: 0
    }

    body.gm-menu-installed .site-header__logo:before {
        top: 20%
    }

    .gm-menu-installed .gm-menu ul.gm-grid .gm-image {
        padding: 0 18% 0 0!important
    }
}

@media only screen and (min-width: 990px) {
    body:not(.gm-menu-installed) nav#AccessibleNav ul li:first-child {
        opacity:0
    }

    body:not(.gm-menu-installed) nav#AccessibleNav ul li:first-child a {
        color: transparent
    }

    body:not(.gm-menu-installed) nav#AccessibleNav ul li:first-child a.site-nav__link--active .site-nav__label {
        border-bottom: 0
    }
}

@media (max-width: 749px) {
    .site-footer__item-inner--text .site-footer__rte img {
        width: 100%;
        height: 100%;
    }

    div#shopify-section-1592226209549 {
        padding-top: 0!important
    }

    .gm-menu-installed .gm-menu.gm-menu-mobile .gm-item.gm-grid-item.gmcol-6 {
        width: 50%!important;
        flex: 1 1 50%!important;
        max-width: 50%!important
    }

    .gm-menu-installed .gm-menu .gm-links>li:not(.gm-heading)>.gm-target,.gm-menu-installed .gm-menu .gm-links>li:not(.gm-heading)>a {
        padding: 8px 0;
        font-size: 14px!important
    }

    .gm-menu-installed .gm-menu .gm-contact .gm-grid-item.gmcol-6:first-of-type,body.gm-menu-installed .gm-menu .gm-contact .gm-grid-item {
        padding-right: 0
    }

    .gm-html .gm-html-inner span.font_md {
        font-size: 20px
    }

    body.gm-menu-installed .site-header .mobile-nav-wrapper ul li:first-child a span.gm-retractor {
        display: flex!important
    }

    .gm-menu-installed .gm-menu .gm-contact .gm-grid-item.gmcol-6:nth-of-type(2) {
        padding-left: 0
    }
}

@media only screen and (max-width: 989px) {
    .gm-menu-installed .gm-menu.gm-menu-mobile .gm-item.gm-grid-item.gmcol-6 {
        width:50%!important;
        flex: 1 1 50%!important;
        max-width: 50%!important
    }

    .gm-html .gm-html-inner .op_hours em.hr_left,.gm-html .gm-html-inner .op_hours em.hr_right {
        width: 50%
    }

    body.gm-menu-installed .gm-menu .gm-links>li:not(.gm-heading)>.gm-target,body.gm-menu-installed .gm-menu .gm-links>li:not(.gm-heading)>a {
        padding: 8px 0;
        font-size: 15px!important
    }

    body.gm-menu-installed .gm-menu .gm-contact .gm-grid-item {
        padding-right: 10px
    }

    .site-header .site-header__icons .site-header__icons-wrapper {
        top: 0
    }
}

@media only screen and (min-width: 1000px) and (max-width:1342px) {
    .gm-html .gm-html-inner .op_hours em.hr_left {
        width:33%
    }

    .gm-html .gm-html-inner .op_hours em.hr_right {
        width: 30%
    }

    .site-header .one-half {
        width: 56%
    }

    .site-header .medium-up--one-quarter {
        width: 23%
    }

    body.gm-menu-installed .site-header__logo:before {
        right: 10px
    }

    .site-header .site-header__logo-image {
        max-width: 225px
    }

    body.gm-menu-installed .gm-menu ul.gm-grid>li:first-child {
        padding-left: 0;
        margin-left: -1px
    }

    body.gm-menu-installed .gm-menu ul.gm-grid .gm-image {
        padding: 0 12% 0 0!important
    }

    body.gm-menu-installed .gm-menu ul.gm-grid {
        width: 52%;
        min-width: 52%;
        margin: 0 auto
    }
}


